:root {
  --primary: #7b26ad;
  --primary2: #bb79e2;
  --teal: #9acee8;
  --teal2: #7fc5eb;
  --green: #09814a;
  --red: #ad2831;
  --dark: #272727;
  --dark2: #333333;
}

::selection {
  background-color: var(--primary);
  color: #fff;
}

::-moz-selection {
  background-color: var(--primary);
  color: #fff;
}

.btn-primary {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:disabled {
  background-color: var(--primary2);
  border: 1px solid var(--primary2);
}

.btn-group .btn-check:checked + .btn-primary.disabled {
  color: #fff;
  background-color: var(--dark2);
  border-color: #fff;
  font-weight: bold;
  cursor: not-allowed;
  opacity: 1; /* Override disabled opacity */
}

.btn-group .btn-check:not(:checked) + .btn-primary {
  color: #fff;
  background-color: var(--primary2);
  border-color: var(--primary2);
  opacity: 0.65;
}

a {
  color: var(--teal);
  text-decoration: none;
}

a:hover {
  color: var(--teal2);
  text-decoration: underline;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.wrapper {
  min-height: 80vh;
  padding-bottom: 40px;
  padding-top: 10px;
}

.section {
  padding-block: 20px;
}

body {
  background-color: var(--dark);
  min-height: 50vh;
  color: #f9f9f9;
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.spinner {
  animation: spin infinite 5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer {
  background-color: var(--dark2);
  min-height: 20vh;
  padding-top: 30px;
  padding-bottom: 20px;
  color: #f9f9f9;
}

.footerLogos {
  color: #f2f3f5 !important;
  background: var(--dark);
  padding: 6px 8px;
  margin-right: 8px;
  font-size: 12px;
  border-radius: 5px;
}

.footerLogos:hover {
  background-color: white;
}

.footerLogos:hover > svg {
  color: var(--dark2) !important;
}

.footerList li {
  margin-bottom: 5px;
}

/*--------------------------------------------------------------
# NavBar
--------------------------------------------------------------*/

.bg-grey {
  border-bottom: 1px solid var(--dark);
  background-color: var(--dark2) !important;
}

.nav-item {
  padding: 0.5rem 1rem;
}

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-item {
  color: white;
}

.nav-link.active {
  font-weight: bold;
}

/*--------------------------------------------------------------
# Main Content
--------------------------------------------------------------*/

.add-sub-btns-container {
  padding: 10px;
  background-color: var(--dark);
  margin-inline: auto;
  border-radius: 5px;
}

.add-sub-btns-container .btn {
  margin-block: 5px;
  min-width: 73px;
  width: 100%;
}

.grey-card {
  padding: 20px;
  background-color: var(--dark2);
  border-radius: 5px;
  margin-inline: auto;
  height: 100%;
}

.list-group-item.active {
  background-color: var(--primary);
  border-color: var(--primary);
}

.note {
  margin-top: 20px;
  background-color: #3d4148;
  padding: 30px;
  border-radius: 10px;
  word-wrap: break-word;
}
